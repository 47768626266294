import React, { useEffect } from "react"
import { PDFViewer, Document, Page, View, Font, Image, Text } from "@react-pdf/renderer"
import { Title, Subtitle, Paragraph, Key, Value, Name, Director } from "./components/Typography"
import Background from "./components/Background"
import moment from "moment"
import OpenSerif from "../../../../../../../../fonts/OpenSerif-Book.ttf"
import YesevaOneRegular from "../../../../../../../../fonts/ArabicFont.ttf"
import OpenSerifBold from "../../../../../../../../fonts/OpenSerifBold.ttf"
import authMieke from "./assets/authMieke.png"
import authDave from "./assets/authDave.png"
import GoboldBoldItalic from "../../../../../../../../fonts/Gobold Thin.otf"
import useFormatMessageForLocale from "../../../../../../../utils/hooks/useFormatMessageForLocale"
import messages from "../../messages"
import { getCodeForLanguageName } from "../../../../../../../utils/language"

interface Props {
  languageTested: string
  score: string
  participantName: string
  finishedTimestamp: string
  certificateRef: string
  location: string
}

Font.register({ family: "OpenSerif", src: OpenSerif })
Font.register({ family: "OpenSerifBold", src: OpenSerifBold })
Font.register({ family: "GoboldBoldItalic", src: GoboldBoldItalic })

const CertificateViewer: React.FC<Props> = ({ languageTested, score, participantName, finishedTimestamp, certificateRef, location }: Props) => {
  Font.register({ family: "YesevaOneRegular", src: YesevaOneRegular })
  const formatMessage = useFormatMessageForLocale(getCodeForLanguageName(languageTested))
  const strippedFinalLevel = score.slice(0, 2)
  const formatLevelMessage = message => {
    return formatMessage(messages[`${strippedFinalLevel}${message}`])
  }
  return (
    <PDFViewer style={{ width: "100%", height: "100vh" }}>
      <Document>
        <Page size="a4" orientation="landscape">
          <Background />
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginTop: "10%",
            }}
          >
            <View style={{ width: "100%" }}>
              <Title>Language Certificate</Title>

              <View style={{ display: "flex", flexDirection: "row", marginTop: 24 }}>
                <Name>{participantName}</Name>
              </View>
              <View style={{ height: 15 }} />

              <Subtitle> {languageTested}</Subtitle>
              <Subtitle> {score}</Subtitle>
              <View style={{ height: 15 }} />

              <View style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                {/* <Key>Level assessed on: </Key> */}
                <Value>{moment(finishedTimestamp).format("LL")}</Value>
              </View>
              <View style={{ height: 8 }} />
              <View style={{ display: "flex", flexDirection: "row", marginTop: 4, justifyContent: "center" }}>
                <Key>Certification ref: </Key>
                <Value>{certificateRef}</Value>
              </View>
              <View style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                <View style={{ width: "50%" }}>
                  <Paragraph>{"VANTAGE: " + formatLevelMessage("Congratulations")}</Paragraph>
                </View>
              </View>
              <View style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: 30 }}>
                <View style={{ display: "flex", alignItems: "center", marginRight: 24 }}>
                  <Image src={authMieke} style={{ height: 50, width: 150 }} />
                  <Director>Mieke Van Oost | CEO</Director>
                </View>
                <View style={{ display: "flex", alignItems: "center", marginLeft: 24 }}>
                  <Image src={authDave} style={{ height: 50, width: 150 }} />
                  <Director>David Michel | Managing Director</Director>
                </View>
              </View>
            </View>
          </View>
          <View style={{ display: "flex", flexGrow: 1, justifyContent: "flex-end", marginBottom: 28, marginLeft: 24 }}>
            <Text style={{ fontSize: 10, fontFamily: "OpenSerif" }}>Rue du petit-chêne 18ter, 1003 Lausanne</Text>
            <Text style={{ fontSize: 10, fontFamily: "OpenSerif" }}>Avenue de Tervurenlaan 36/18, 1040 Brussels</Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  )
}

export default CertificateViewer
