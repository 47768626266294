import React, { useEffect } from "react"
import queryString from "query-string"
import { useSelector, useDispatch } from "react-redux"
import { fetchLevelEvaluationByToken } from "../store/levelEvaluationByToken/actions"
import { levelEvaluationByTokenSelector } from "../store/levelEvaluationByToken/selectors"
import notifications from "../utils/notifications"
import CertificateViewer from "../components/test/question/components/final/components/certificate"
import _ from "lodash"
import Loader from "../components/base/loaders/loader"
import { getLanguageName } from "../utils/language"

const CertificatePage = () => {
  const dispatch = useDispatch()

  const search = queryString.parse(window.location.search)
  let levelEvaluationToken = search?.levelevaluation
  // If we are passed a level evaluation token as GET parameter, take that one
  if (!levelEvaluationToken) {
    window.location.href = "https://www.atolo.eu/"
  }

  useEffect(() => {
    if (levelEvaluationToken) {
      // Fetch the level evaluation
      dispatch(fetchLevelEvaluationByToken(levelEvaluationToken))
    }
  }, [levelEvaluationToken])

  const { error, result, loading } = useSelector(levelEvaluationByTokenSelector(levelEvaluationToken))

  useEffect(() => {
    if (error) {
      notifications.error("Oops! Something went wrong.", error.message, error.details)
    }
  }, [error])

  return (
    <>
      {!loading && result && result.finallevel && (
        <CertificateViewer
          languageTested={getLanguageName(_.get(result, "evaluatedlanguage").toUpperCase())}
          score={_.get(result, "finallevel")}
          finishedTimestamp={_.get(result, "finishedtimestamp")}
          participantName={_.get(result, "person.commonname")}
          certificateRef="Common European Framework of Reference"
        />
      )}
      {loading && <Loader />}
    </>
  )
}

export default CertificatePage
