import React from "react"
import { Text } from "@react-pdf/renderer"

const Title: React.FC = ({ children }) => (
  <Text style={{ fontFamily: "GoboldBoldItalic", color: "#7BB826", fontSize: 40, marginBottom: 12, textAlign: "center" }}>{children}</Text>
)

const Subtitle: React.FC = ({ children }) => <Text style={{ fontFamily: "OpenSerif", fontSize: 19, textAlign: "center" }}>{children}</Text>

const Paragraph: React.FC = ({ children }) => (
  <Text style={{ fontFamily: "OpenSerif", fontSize: 12, marginTop: 16, textAlign: "center" }}>{children}</Text>
)

const Key: React.FC = ({ children }) => <Text style={{ fontFamily: "OpenSerifBold", fontSize: 15 }}>{children}</Text>

const Value: React.FC = ({ children }) => <Text style={{ fontFamily: "OpenSerif", fontSize: 15 }}>{children}</Text>

const Name: React.FC = ({ children }) => <Text style={{ fontFamily: "YesevaOneRegular", fontSize: 34, textAlign: "center" }}>{children}</Text>

const Director: React.FC = ({ children }) => <Text style={{ fontFamily: "OpenSerif", fontSize: 15 }}>{children}</Text>
export { Title, Subtitle, Paragraph, Key, Value, Name, Director }
